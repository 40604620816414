<template>
    <div>
        <div class="l-inline l-center-v l-gap-2 filter-actions">
            <i18n path="showOf">
                <b>{{ filteredTrucks.length }}</b>
                <b>{{ trucks.length }}</b>
            </i18n>

            <a v-if="isClearFilterVisible" @click="$emit('clearFilter')">
                {{ $t('clearFilter') }}
            </a>
        </div>

        <table v-infinite-scroll="loadMoreTrucks" infinite-scroll-distance="25">
            <thead>
                <tr>
                    <th>{{ $t('shared.lsva.columns.type') }}</th>

                    <th>
                        {{ $t('shared.lsva.columns.name') }}

                        <IconButton @click="handleSort('name')">
                            <SortArrowIcon
                                :direction="
                                    sortBy === 'name' ? sortDirection : 0
                                "
                                width="10"
                                height="10"
                            />
                        </IconButton>
                    </th>

                    <th>
                        {{ $t('shared.lsva.columns.vin') }}

                        <IconButton @click="handleSort('vin')">
                            <SortArrowIcon
                                :direction="
                                    sortBy === 'vin' ? sortDirection : 0
                                "
                                width="10"
                                height="10"
                            />
                        </IconButton>
                    </th>

                    <th>
                        {{ $t('shared.lsva.columns.duration') }}

                        <IconButton @click="handleSort('duration')">
                            <SortArrowIcon
                                :direction="
                                    sortBy === 'duration' ? sortDirection : 0
                                "
                                width="10"
                                height="10"
                            />
                        </IconButton>
                    </th>

                    <th>
                        {{ $t('shared.lsva.columns.distance') }}

                        <IconButton @click="handleSort('distance')">
                            <SortArrowIcon
                                :direction="
                                    sortBy === 'distance' ? sortDirection : 0
                                "
                                width="10"
                                height="10"
                            />
                        </IconButton>
                    </th>

                    <th>
                        {{ $t('shared.lsva.columns.drives') }}

                        <IconButton @click="handleSort('leg_count')">
                            <SortArrowIcon
                                :direction="
                                    sortBy === 'leg_count' ? sortDirection : 0
                                "
                                width="10"
                                height="10"
                            />
                        </IconButton>
                    </th>

                    <th>
                        {{ $t('shared.lsva.columns.endOfRegistration') }}

                        <IconButton @click="handleSort('endOfRegistration')">
                            <SortArrowIcon
                                :direction="
                                    sortBy === 'endOfRegistration'
                                        ? sortDirection
                                        : 0
                                "
                                width="10"
                                height="10"
                            />
                        </IconButton>
                    </th>

                    <th>
                        {{ $t('shared.lsva.columns.message') }}

                        <IconButton @click="handleSort('status')">
                            <SortArrowIcon
                                :direction="
                                    sortBy === 'status' ? sortDirection : 0
                                "
                                width="10"
                                height="10"
                            />
                        </IconButton>
                    </th>
                    <th>&nbsp;</th>
                </tr>
            </thead>

            <tr
                v-for="item in trucksPortion"
                :key="item.id"
                :class="{ selected: selected === item }"
                @click="setSelectedTruck(item)"
            >
                <td>
                    <TruckTypeIcon type="truck" />
                </td>

                <td>
                    {{ item.toll_declaration.truck.name }}
                </td>

                <td>
                    {{ item.toll_declaration.truck.vin }}
                </td>

                <td>
                    {{ formatDuration(item.duration) }}
                </td>

                <td>
                    {{ formatDistance(item.distance) }}
                </td>

                <td>
                    {{ item.leg_count }}&nbsp;{{
                        $t('shared.lsva.columns.drives')
                    }}
                </td>

                <td>
                    {{ item.toll_declaration.truck.end_date || $t('noEnd') }}
                </td>

                <td>
                    {{ $t(`shared.lsva.truckStatus.${item.declaration_type}`) }}
                </td>

                <td>
                    <Pill v-if="item.needs_manual_correction" variant="danger">
                        {{ $t('trackingError') }}
                    </Pill>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import moment from 'moment'
import { mapState, mapActions } from 'vuex'
import infiniteScroll from 'vue-infinite-scroll'

import IconButton from '@/components/IconButton'
import Pill from '@/components/redesigned/Pill.vue'
import SortArrowIcon from '@/components/icons/SortArrowIcon'
import TruckTypeIcon from '@/components/icons/TruckTypeIcon.vue'

import { formatHelper } from '@/utils'

const defaultScrollLimit = 15
const defaultSortColumn = 'name'
const defaultSortDirection = 1

export default {
    name: 'LsvaTrucksTableView',
    components: {
        IconButton,
        Pill,
        SortArrowIcon,
        TruckTypeIcon,
    },
    directives: {
        infiniteScroll,
    },
    props: {
        filterSearch: {
            type: String,
            default: '',
        },
        filterStatus: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            selected: [],
            scrollLimit: defaultScrollLimit,
            scrollStep: defaultScrollLimit,
            sortBy: defaultSortColumn,
            sortDirection: defaultSortDirection,
        }
    },
    computed: {
        ...mapState('lsva', ['trucks']),
        filteredTrucks() {
            return this.trucks
        },
        trucksPortion() {
            return this.filteredTrucks.length === this.trucks.length
                ? this.filteredTrucks.slice(0, this.scrollLimit)
                : this.filteredTrucks
        },
        isClearFilterVisible() {
            return false
        },
    },
    watch: {
        selected(value) {
            this.$emit('update:selected', value)
        },
    },
    methods: {
        ...mapActions('lsva', ['setSelectedTruck']),
        handleSort(column) {
            if (this.sortBy !== column || this.sortDirection !== 1) {
                this.sortBy = column
                this.sortDirection = 1
            } else if (this.sortDirection === 1) {
                this.sortDirection = -1
            }
        },
        loadMoreTrucks() {
            if (this.scrollLimit < this.trucks.length) {
                this.scrollLimit += this.scrollStep
            }
        },
        formatDistance(distance) {
            return formatHelper.formatDistance(distance, 0)
        },
        formatDuration(duration) {
            return moment.duration(duration, 'seconds').humanize()
        },
    },
}
</script>

<i18n>
{
    "en": {
        "clearFilter": "Clear filter",
        "showOf": "Show {0} of {1}",
        "trackingError": "Tracking error",
        "noEnd": "No end"
    },
    "de": {
        "clearFilter": "Filter zurücksetzen",
        "showOf": "Zeige {0} von {1}",
        "trackingError": "Trackingfehler",
        "noEnd": "Kein Ende"
    },
    "fr": {
        "clearFilter": "Supprimer le filtre",
        "showOf": "Afficher {0} de {1}",
        "trackingError": "Erreur de suivi",
        "noEnd": "Pas de fin"
    },
    "it": {
        "clearFilter": "Annullare il filtro",
        "showOf": "Listo {0} di {1}",
        "trackingError": "Errore di tracciamento",
        "noEnd": "Nessuna fine"
    }
}
</i18n>

<style lang="scss" scoped>
.filter-actions {
    position: sticky;
    top: 0;
    left: 0;
    padding: 1rem;
    height: 52px;
    background-color: #fff;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.7);
    z-index: 1;

    a {
        font-size: 13px;
        color: #000;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }
    }

    button {
        position: absolute;
        right: 2rem;
        color: #000;
    }
}

.checkbox-cell {
    height: 100%;
    padding: 0;

    input,
    label {
        cursor: pointer;
    }

    label {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0.5rem 1rem 0.5rem 2rem;
    }
}

table {
    display: block;
    width: 100%;
    border-spacing: 0;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.7);
    border-bottom: $style-border;

    thead {
        position: sticky;
        top: 52px;
        background-color: #fff;
        z-index: 1;

        .icon-button {
            margin-left: 5px;
        }
    }

    tr {
        height: 100%;

        &.selected {
            background-color: $color-gray-lighter;
        }

        &:not(:first-child) {
            cursor: pointer;

            &:hover {
                background-color: $color-gray-lighter;
            }

            & + tr {
                td {
                    border-top: $style-border;
                }
            }
        }

        th {
            padding: 1rem;
            border-top: $style-border;
            border-bottom: $style-border;
            text-align: left;

            &.action {
                padding-top: 0;
                padding-bottom: 0;
                font-weight: normal;

                & > * {
                    width: 240px;
                }
            }

            &.dynamic {
                cursor: grab;
                transition: box-shadow 0.1s;

                &:active {
                    cursor: grabbing;
                }

                &:hover {
                    box-shadow: 0 0 4px rgba(0, 0, 0, 0.08);
                }

                &.sortable-chosen,
                &.sortable-ghost {
                    background-color: #fff;
                    box-shadow: 0 0 8px rgba(0, 0, 0, 0.08);
                }
            }
        }

        td {
            padding: 0.5rem 1rem;
        }

        th,
        td {
            white-space: nowrap;

            &:not(:last-child) {
                width: 1%;
            }
        }
    }
}
</style>
