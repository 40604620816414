<template>
    <div class="lsva-filter">
        <VSpinner
            v-if="isCalendarLoading"
            :size="24"
            :speed="1"
            line-fg-color="black"
            line-bg-color="transparent"
        />
        <div v-else class="lsva-filter__calendar">
            <!-- Selected Day Display -->
            <div class="lsva-filter__header">
                <h2 class="lsva-filter__header-title">
                    {{ formattedselectedDate }}
                </h2>
                <span class="lsva-filter__header-week">
                    {{ $t('calendarWeek') }} {{ selectedWeek }}
                </span>
            </div>

            <!-- Date Navigation -->
            <div class="lsva-filter__days-container">
                <button
                    class="lsva-filter__nav-button"
                    :disabled="isLeftDisabled"
                    @click="scrollDays(-1)"
                >
                    <ChevronLeftIcon width="9" height="17" />
                </button>
                <div class="lsva-filter__days-wrapper">
                    <div class="lsva-filter__days">
                        <div
                            v-for="day in visibleDays"
                            :key="day.date"
                            class="lsva-filter__day"
                            :class="{
                                'lsva-filter__day--selected':
                                    day.date === selectedDay.date,
                                'lsva-filter__day--sunday': day.isSunday,
                            }"
                            @click="selectDay(day)"
                        >
                            <div class="lsva-filter__day-label">
                                {{ day.label }}
                            </div>
                            <div class="lsva-filter__day-number">
                                {{ day.dayNumber }}. {{ monthAbbr }}
                            </div>
                            <div
                                v-if="day.hasMarker"
                                class="lsva-filter__day-marker"
                            ></div>
                        </div>
                    </div>
                </div>
                <button
                    class="lsva-filter__nav-button"
                    :disabled="isRightDisabled"
                    @click="scrollDays(1)"
                >
                    <ChevronRightIcon width="9" height="17" />
                </button>
            </div>
        </div>
        <div class="lsva-filter__inputs">
            <div>
                <BaseInput
                    :value="searchQuery"
                    :debounce="400"
                    :placeholder="$t('searchForTrucks')"
                    block
                    @input="setSearchQuery"
                >
                    <template #icon>
                        <SearchIcon width="16" height="16" />
                    </template>
                </BaseInput>
            </div>
            <div>
                <BaseMultiselect
                    :value="searchStatus"
                    :placeholder="$t('allStatuses')"
                    :options="statusOptions"
                    :custom-label="
                        option => $t(`shared.lsva.truckStatus.${option}`)
                    "
                    block
                    @input="setSearchStatus"
                />
            </div>
        </div>
    </div>
</template>

<script>
import VSpinner from 'vue-simple-spinner'
import moment from 'moment'

import { mapActions, mapState, mapGetters } from 'vuex'

import ChevronLeftIcon from './icons/ChevronLeftIcon'
import ChevronRightIcon from './icons/ChevronRightIcon'
import BaseMultiselect from './redesigned/BaseMultiselect'
import BaseInput from './redesigned/BaseInput'
import SearchIcon from './icons/SearchIcon'

const MAX_VISIBLE_DAYS = 7 // Configurable visible days

export default {
    name: 'DateSelect',
    components: {
        BaseInput,
        BaseMultiselect,
        ChevronLeftIcon,
        ChevronRightIcon,
        SearchIcon,
        VSpinner,
    },
    data() {
        return {
            visibleStartIndex: 0, // Calculated dynamically
            maxVisibleDays: window.innerWidth < 896 ? 4 : MAX_VISIBLE_DAYS, // show 4 days on mobile
            statusOptions: [
                'ALL',
                'VEHICLE_OUTSIDE_TOLL_DOMAIN',
                'GNSS',
                'WITHOUT_ACCESS',
            ],
        }
    },
    computed: {
        ...mapState('lsva', [
            'isCalendarLoading',
            'searchQuery',
            'searchStatus',
            'selectedDay',
        ]),
        ...mapGetters('lsva', ['preparedCalendarDays']),

        visibleDays() {
            return this.preparedCalendarDays.slice(
                this.visibleStartIndex,
                this.visibleStartIndex + this.maxVisibleDays
            )
        },
        isLeftDisabled() {
            return this.visibleStartIndex === 0
        },
        isRightDisabled() {
            return (
                this.visibleStartIndex + this.maxVisibleDays >=
                this.preparedCalendarDays.length
            )
        },
        formattedselectedDate() {
            return moment(this.selectedDay.date).format('dddd, D. MMMM')
        },
        selectedWeek() {
            return moment(this.selectedDay.date).isoWeek()
        },
        monthAbbr() {
            return moment().format('MMM')
        },
    },
    async created() {
        moment.locale(this.$i18n.locale)

        await this.loadCalendarDays()

        this.visibleStartIndex = Math.max(
            this.preparedCalendarDays.length - this.maxVisibleDays,
            0
        )

        this.selectDay(
            this.preparedCalendarDays[this.preparedCalendarDays.length - 1]
        )
    },
    methods: {
        ...mapActions('lsva', [
            'loadCalendarDays',
            'setSearchQuery',
            'setSearchStatus',
            'setSelectedDay',
        ]),
        selectDay(day) {
            this.setSelectedDay(day)
        },
        scrollDays(direction) {
            this.visibleStartIndex = Math.min(
                Math.max(this.visibleStartIndex + direction, 0),
                this.preparedCalendarDays.length - this.maxVisibleDays
            )
        },
    },
}
</script>

<i18n>
{
    "en": {
        "allStatuses": "All Messages",
        "calendarWeek": "Calendar week",
        "searchForTrucks": "Search for trucks"
    },
    "de": {
        "allStatuses": "Alle Meldungen",
        "calendarWeek": "Kalenderwoche",
        "searchForTrucks": "Suche nach Lastwagen"
    },
    "fr": {
        "allStatuses": "Tous les Messages",
        "calendarWeek": "Semaine Civile",
        "searchForTrucks": "Recherche de camions"
    },
    "it": {
        "allStatuses": "Tutti i Messaggi",
        "calendarWeek": "Settimana di Calendario",
        "searchForTrucks": "Ricerca di camion"
    }
}
</i18n>

<style lang="scss" scoped>
.lsva-filter {
    width: 100%;
    &__calendar {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;

        @include respond-to('for-mobile-down') {
            flex-direction: column;
            gap: 16px;
        }
    }

    &__inputs {
        border-top: $style-border;
        padding: 24px 0;
        display: flex;
        justify-content: flex-end;
        gap: 16px;
    }

    &__header {
        color: $color-primary;

        &-week {
            color: $color-primary-light;
        }
    }

    &__days-container {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        box-shadow: 1px 1px 4px 0 #00000026;
    }

    &__days-wrapper {
        overflow: hidden;
        width: 100%;

        @include respond-to('for-mobile-down') {
            overflow-x: auto;
        }
    }

    &__days {
        display: flex;
    }

    &__day {
        flex: 1;
        width: 70px;
        text-align: center;
        padding: 8px;
        cursor: pointer;
        position: relative;
        background-color: $background-input;
        color: $color-primary;

        &--sunday {
            color: $color-blue-light;
        }

        &--selected {
            background-color: $color-primary;
            color: $color-primary-contrast;
        }

        &-label {
            font-weight: bold;
        }

        &-number {
            white-space: nowrap;
        }

        &-marker {
            width: 6px;
            height: 6px;
            background-color: $color-orange;
            border-radius: 50%;
            position: absolute;
            top: 5px;
            right: 5px;
        }
    }

    &__nav-button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        padding: 12px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:disabled {
            opacity: 0.3;
            cursor: not-allowed;
        }
    }
}
</style>
