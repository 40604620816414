<template>
    <div class="lsva-trip-create">
        <h4 class="lsva-trip-create__header">{{ title }}</h4>
        <div class="lsva-trip-create__form">
            <BaseTimeRange v-model="trip.time" />

            <BaseInput
                v-model="trip.distance"
                :placeholder="$t('distance')"
                type="number"
                block
            >
                <template #icon>
                    <DistanceIcon width="16" height="16" />
                </template>
                <template #postfix>
                    {{ $t('shared.units.km') }}
                </template>
            </BaseInput>

            <BaseMultiselect
                v-model="trip.trailer"
                :options="trailers"
                :placeholder="$t('trailer')"
                label="label"
                block
            />

            <BaseInput
                v-model="trip.weight"
                :placeholder="$t('weight')"
                type="number"
                block
            >
                <template #icon>
                    <ScalesIcon width="16" height="16" />
                </template>
                <template #postfix>
                    {{ $t('shared.units.tons') }}
                </template>
            </BaseInput>

            <BaseMultiselect
                v-model="trip.message"
                :options="['wrongTrailerDetected', 'noGpsSignal']"
                :custom-label="option => $t(`reasons.${option}`)"
                :placeholder="$t('message')"
                block
            />
        </div>
        <div class="lsva-trip-create__footer">
            <BaseButton size="small" @click="create">{{
                $t('create')
            }}</BaseButton>
            <BaseButton size="small" variant="outline" @click="cancel">{{
                $t('cancel')
            }}</BaseButton>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import BaseMultiselect from '@/components/redesigned/BaseMultiselect.vue'
import BaseInput from '@/components/redesigned/BaseInput.vue'
import BaseButton from '@/components/redesigned/BaseButton.vue'
import BaseTimeRange from '@/components/base/BaseTimeRange.vue'
import DistanceIcon from '@/components/icons/DistanceIcon.vue'
import ScalesIcon from '@/components/icons/ScalesIcon.vue'

// TODO: remove this when the API is ready
const TRAILERS = [
    { value: '', label: 'None', weight: null },
    { value: 'Trailer 1', label: 'Trailer 1', weight: 2.5 },
    { value: 'Trailer 2', label: 'Trailer 2', weight: 2.7 },
    { value: 'Trailer 3', label: 'Trailer 3', weight: 3.8 },
]

export default {
    name: 'LsvaTripCreate',
    components: {
        BaseTimeRange,
        BaseButton,
        BaseMultiselect,
        BaseInput,
        DistanceIcon,
        ScalesIcon,
    },
    props: {
        title: {
            type: String,
            default: 'Create Trip',
        },
    },
    data() {
        return {
            trip: {
                time: [
                    moment()
                        .subtract(2, 'hours')
                        .format('HH:mm'),
                    moment().format('HH:mm'),
                ],
                trailer: TRAILERS[0],
                weight: '',
                distance: '',
                reason: '',
            },
            trailers: TRAILERS,
        }
    },
    watch: {
        'trip.trailer'(trailer) {
            this.trip.weight = trailer.weight
        },
    },
    methods: {
        create() {
            console.log('Creating trip', this.trip)
            this.$emit('create', this.trip)
            this.$notify(this.$t('tripCreated'))
        },
        cancel() {
            this.$emit('close')
        },
    },
}
</script>

<i18n>
{
    "en": {
        "time": "Time from - to",
        "distance": "Distance driven in KM",
        "trailer": "Trailer",
        "weight": "Total weight of the trailer",
        "message": "Reason for manual entry",
        "create": "Save",
        "cancel": "Cancel",
        "tripCreated": "Trip created",
        "reasons" : {
            "wrongTrailerDetected": "Wrong trailer detected",
            "noGpsSignal": "No GPS signal"
        }
    },
    "de": {
        "time": "Zeit von - bis",
        "distance": "Gefahrene Distanz in KM",
        "trailer": "Anhänger",
        "weight": "Gesamtgewicht des Anhängers",
        "message": "Grund für manuelle Erfassung",
        "create": "Speichern",
        "cancel": "Abbrechen",
        "tripCreated": "Fahrt erstellt",
        "reasons" : {
            "wrongTrailerDetected": "Falscher Anhänger erkannt",
            "noGpsSignal": "Kein GPS-Signal"
        }
      },
    "fr": {
        "time": "Temps de - à",
        "distance": "Distance parcourue en KM",
        "trailer": "Remorque",
        "weight": "Poids total de la remorque",
        "message": "Raison de l'entrée manuelle",
        "create": "Sauvegarder",
        "cancel": "Annuler",
        "tripCreated": "Trajet créé",
        "reasons" : {
            "wrongTrailerDetected": "Mauvaise remorque détectée",
            "noGpsSignal": "Pas de signal GPS"
        }
    },
    "it":
    {
        "time": "Tempo da - a",
        "distance": "Distanza percorsa in KM",
        "trailer": "Rimorchio",
        "weight": "Peso totale del rimorchio",
        "message": "Motivo dell'inserimento manuale",
        "create": "Salva",
        "cancel": "Annulla",
        "tripCreated": "Viaggio creato",
        "reasons" : {
            "wrongTrailerDetected": "Rimorchio sbagliato rilevato",
            "noGpsSignal": "Nessun segnale GPS"
        }
    }
}
</i18n>

<style lang="scss" scoped>
.lsva-trip-create {
    padding: 24px;
    border-radius: 12px;
    background: $color-gray-light-2;

    &__header {
        margin-bottom: 24px;
        font-size: 16px;
        font-weight: 700;
    }

    &__form {
        display: flex;
        flex-direction: column;
        grid-gap: 16px;
    }

    &__footer {
        margin-top: 24px;
        display: flex;
        gap: 8px;
    }
}
</style>
