<template>
    <div class="lsva-truck-details">
        <div class="lsva-truck-details__head">
            <h3>{{ $t('heading') }}</h3>
            <IconButton @click="$emit('close')">
                <RemoveIcon width="24" height="24" />
            </IconButton>
        </div>
        <div class="lsva-truck-details__info">
            <div class="lsva-truck-details__icon">
                <TruckTypeIcon type="truck" />
            </div>
            <div class="lsva-truck-details__data">
                <h4>{{ selectedTruck.toll_declaration.truck.name }}</h4>
                <p>VIN {{ selectedTruck.toll_declaration.truck.vin }}</p>
                <p>
                    {{ $t('shared.lsva.columns.endOfRegistration') }}
                    {{
                        selectedTruck.toll_declaration.truck.end_date ||
                            $t('noEnd')
                    }}
                </p>
            </div>
            <div class="lsva-truck-details__edit">
                <IconButton @click="handleEditClick">
                    <PencilIcon width="16" height="16" />
                </IconButton>
            </div>
        </div>
        <BaseAlert
            v-if="selectedTruck.needs_manual_correction"
            variant="danger"
        >
            {{ $t('noTrackingWarning') }}
        </BaseAlert>
        <div class="lsva-truck-details__info">
            <div
                class="lsva-truck-details__data lsva-truck-details__data--accent"
            >
                {{ formattedDate }}
            </div>
            <div>
                <BaseButton size="small" @click="handleCreateTripClick">{{
                    $t('addTrip')
                }}</BaseButton>
            </div>
        </div>
        <div class="lsva-truck-details__trips">
            <VSpinner
                v-if="isTripsLoading"
                :size="24"
                :speed="1"
                line-fg-color="black"
                line-bg-color="transparent"
                class="lsva-truck-details__trips-spinner"
            />
            <template v-else>
                <LsvaTrip
                    v-for="(trip, index) in preparedTrips"
                    :key="trip.id"
                    :title="`${$t('trip')} ${index + 1}`"
                    :trip="trip"
                    :has-error="selectedTruck.needs_manual_correction"
                    :error-text="selectedTruck.declaration_type"
                />
            </template>

            <LsvaTripCreate
                v-show="isCreateTripVisible"
                ref="createTripForm"
                :title="newTripName"
                class="lsva-truck-details__trip-create-form"
                @close="handleCloseCreateTrip"
                @create="handleCloseCreateTrip"
            />
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import VSpinner from 'vue-simple-spinner'
import { mapState, mapGetters } from 'vuex'

import BaseAlert from '@/components/base/BaseAlert.vue'
import BaseButton from '@/components/redesigned/BaseButton.vue'
import IconButton from '@/components/IconButton.vue'
import LsvaTripCreate from '@/components/LsvaTripCreate.vue'
import LsvaTrip from '@/components/LsvaTrip.vue'
import PencilIcon from '@/components/icons/PencilIcon.vue'
import RemoveIcon from '@/components/icons/RemoveIcon.vue'
import TruckTypeIcon from '@/components/icons/TruckTypeIcon.vue'

export default {
    name: 'LsvaTruckDetailsView',
    components: {
        LsvaTrip,
        BaseAlert,
        BaseButton,
        IconButton,
        LsvaTripCreate,
        PencilIcon,
        RemoveIcon,
        TruckTypeIcon,
        VSpinner,
    },
    data() {
        return {
            isEditing: false,
            isCreateTripVisible: false,
        }
    },
    computed: {
        ...mapState('lsva', ['selectedDay', 'selectedTruck', 'isTripsLoading']),
        ...mapGetters('lsva', ['preparedTrips']),
        formattedDate() {
            return moment(this.selectedDay.date).format('dddd, D. MMMM')
        },
        newTripName() {
            return `${this.$t('trip')} ${(this.trips?.length ?? 0) + 1}`
        },
    },
    methods: {
        handleEditClick() {
            this.isEditing = true
        },
        handleCreateTripClick() {
            this.isCreateTripVisible = true
            this.$nextTick(() => {
                this.$refs.createTripForm.$el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                })
            })
        },
        handleCloseCreateTrip() {
            this.isCreateTripVisible = false
        },
    },
}
</script>

<i18n>
{
    "en": {
        "heading": "Edit",
        "noTrackingWarning": "Signal to the tracker was lost during the day. Please check the trips and complete if necessary.",
        "addTrip": "Add trip",
        "trailer": "Trailer",
        "weight": "Weight",
        "trip": "Trip",
        "noEnd": "No end"
    },
    "de": {
        "heading": "Bearbeiten",
        "noTrackingWarning": "Während dem Tag ging das Signal zum Tracker verloren. Bitte überprüfen Sie die Fahrten und vervollständigen Sie bei Bedarf.",
        "addTrip": "Fahrt hinzufügen",
        "trailer": "Anhänger",
        "weight": "Gewicht",
        "trip": "Fahrt",
        "noEnd": "Kein Ende"
    },
    "fr": {
        "heading": "Editer",
        "noTrackingWarning": "Le signal au tracker a été perdu pendant la journée. Veuillez vérifier les trajets et les compléter si nécessaire.",
        "addTrip": "Ajouter un trajet",
        "trailer": "Remorque",
        "weight": "Poids",
        "trip": "Trajet",
        "noEnd": "Pas de fin"
    },
    "it": {
        "heading": "Modificare",
        "noTrackingWarning": "Il segnale al tracker è stato perso durante il giorno. Controllare i viaggi e completare se necessario.",
        "addTrip": "Aggiungi viaggio",
        "trailer": "Rimorchio",
        "weight": "Peso",
        "trip": "Viaggio",
        "noEnd": "Nessuna fine"
    }
}
</i18n>

<style lang="scss" scoped>
.lsva-truck-details {
    padding: 0 1rem;

    &__head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: $style-border;
        padding: 16px 0;
    }

    &__info {
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 16px 0;
    }

    &__data {
        &--accent {
            font-weight: bold;
        }
        flex-grow: 1;
    }

    &__trips {
        border-top: $style-border;
    }

    &__trips-spinner {
        margin-top: 8px;
    }

    &__trip-create-form {
        margin: 24px 0;
    }
}
</style>
