<template>
    <div class="base-time-range">
        <vue-timepicker
            v-model="timeStart"
            format="HH:mm"
            :placeholder="$t('from')"
            :minute-interval="1"
            :minute-step="1"
            class="base-time-range__time"
            @change="() => $emit('input', [timeStart, timeEnd])"
        />
        <span>-</span>
        <vue-timepicker
            v-model="timeEnd"
            format="HH:mm"
            :placeholder="$t('to')"
            :minute-interval="1"
            :minute-step="1"
            class="base-time-range__time"
            @change="() => $emit('input', [timeStart, timeEnd])"
        />
    </div>
</template>
<script>
import VueTimepicker from 'vue2-timepicker/src'

export default {
    name: 'BaseTimeRange',
    components: {
        VueTimepicker,
    },
    props: {
        value: {
            type: Array,
            default: () => ['', ''], // [timeStart, timeEnd]
        },
    },
    data() {
        return {
            timeStart: this.value[0],
            timeEnd: this.value[1],
        }
    },
}
</script>

<i18n>
{
    "en": {
        "from": "From",
        "to": "To"
    },
    "de": {
        "from": "Von",
        "to": "Bis"
    },
    "fr": {
        "from": "De",
        "to": "À"
        },
    "it": {
        "from": "Da",
        "to": "A"
    }
}
</i18n>

<style lang="scss">
.base-time-range {
    display: flex;
    align-items: center;
    gap: 8px;

    &__time.time-picker .display-time {
        @include input-style;
        @include input-style-block;
    }
}
</style>
