<template>
    <div class="truck-type-icon">
        <TruckIcon width="24" height="24" />
    </div>
</template>

<script>
import TruckIcon from '@/components/icons/TruckIcon.vue'

export default {
    name: 'TruckTypeIcon',
    components: {
        TruckIcon,
    },
    props: {
        type: {
            type: String,
            required: true,
            validator: value => ['truck', 'trailer'].includes(value), // TODO: extend with other types
        },
    },
}
</script>

<style lang="scss" scoped>
.truck-type-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: 1px solid $color-text;
}
</style>
