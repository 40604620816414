<template>
    <div class="trip l-padded-y l-stack l-gap-2">
        <div class="l-inline">
            <div class="l-stack l-gap-1 l-grow-1">
                <h4>
                    {{ title }}
                </h4>
                <div v-if="hasError">
                    <Pill variant="danger">
                        {{ $t(`shared.lsva.truckStatus.${errorText}`) }}
                    </Pill>
                </div>
                <div>
                    <p>{{ startHours }} - {{ endHours }}</p>
                    <p>{{ duration }} ({{ trip.distance }}&nbsp;km)</p>
                    <p v-if="trip.trailer">
                        {{ $t('trailer') }}: {{ trip.trailer.name }}
                    </p>
                    <p v-if="trip.trailer">
                        {{ $t('weight') }}: {{ trailerWeight }}&nbsp;{{
                            $t('shared.units.tons')
                        }}
                    </p>
                </div>
            </div>
            <div>
                <IconButton @click="toggleCorrectionForm">
                    <AddPenIcon width="16" height="16" />
                </IconButton>
            </div>
        </div>
        <div
            v-if="isCorrectionFormVisible"
            class="trip__correction-form l-stack l-gap-2"
        >
            <h4 class="trip__correction-form-header">
                {{ $t('addCorrection') }}
            </h4>
            <div class="l-stack l-gap-1">
                <BaseTimeRange v-model="tripCorrection.time" />

                <BaseMultiselect
                    v-model="tripCorrection.trailer"
                    :options="trailers"
                    track-by="id"
                    :custom-label="option => option.name"
                    :placeholder="$t('trailer')"
                    :loading="isTrailersLoading"
                    label="label"
                    block
                />

                <BaseInput
                    v-model="tripCorrection.weight"
                    :placeholder="$t('weight')"
                    type="number"
                    block
                >
                    <template #icon>
                        <ScalesIcon width="16" height="16" />
                    </template>
                    <template #postfix>
                        {{ $t('shared.units.tons') }}
                    </template>
                </BaseInput>
            </div>
            <div class="l-inline l-gap-1">
                <BaseButton
                    size="small"
                    :disabled="!isCorrectionFormValid"
                    :is-loading="isCorrectionPending"
                    @click="create"
                >
                    {{ $t('create') }}
                </BaseButton>
                <BaseButton
                    size="small"
                    variant="outline"
                    @click="toggleCorrectionForm"
                >
                    {{ $t('cancel') }}
                </BaseButton>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapState } from 'vuex'

import AddPenIcon from '@/components/icons/AddPenIcon.vue'
import BaseButton from '@/components/redesigned/BaseButton.vue'
import BaseInput from '@/components/redesigned/BaseInput.vue'
import BaseMultiselect from '@/components/redesigned/BaseMultiselect.vue'
import BaseTimeRange from '@/components/base/BaseTimeRange.vue'
import IconButton from '@/components/IconButton.vue'
import Pill from '@/components/redesigned/Pill.vue'
import ScalesIcon from '@/components/icons/ScalesIcon.vue'

export default {
    name: 'LsvaTrip',
    components: {
        AddPenIcon,
        BaseButton,
        BaseInput,
        BaseMultiselect,
        BaseTimeRange,
        IconButton,
        Pill,
        ScalesIcon,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        trip: {
            type: Object,
            required: true,
        },
        hasError: {
            type: Boolean,
            default: false,
        },
        errorText: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            isCorrectionFormVisible: false,
            tripCorrection: {
                time: [
                    moment()
                        .subtract(2, 'hours')
                        .format('HH:mm'),
                    moment().format('HH:mm'),
                ],
                trailer: null,
                weight: null,
            },
        }
    },
    computed: {
        ...mapState('lsva', [
            'trailers',
            'isTrailersLoading',
            'selectedDay',
            'selectedTruck',
            'isCorrectionPending',
        ]),
        trailerWeight() {
            return (this.trip.trailer.weight / 1000).toFixed(2)
        },
        duration() {
            const start = moment(this.trip.start)
            const end = moment(this.trip.end)
            const duration = moment.duration(end.diff(start))

            return `${duration.hours()}h ${duration.minutes()}m`
        },
        startHours() {
            return moment(this.trip.start).format('HH:mm')
        },
        endHours() {
            return moment(this.trip.end).format('HH:mm')
        },
        isCorrectionFormValid() {
            return (
                this.tripCorrection.time[0] &&
                this.tripCorrection.time[1] &&
                this.tripCorrection.trailer &&
                this.tripCorrection.weight
            )
        },
    },
    watch: {
        'tripCorrection.trailer'(trailer) {
            if (!trailer) {
                return
            }

            this.tripCorrection.weight = (trailer.weight / 1000).toFixed(2)
        },
    },
    methods: {
        ...mapActions('lsva', ['createCorrection']),
        toggleCorrectionForm() {
            this.isCorrectionFormVisible = !this.isCorrectionFormVisible
        },
        async create() {
            await this.createCorrection({
                declaration_period: this.selectedDay.date,
                correction_begin: `${this.selectedDay.date}T${this.tripCorrection.time[0]}`,
                correction_end: `${this.selectedDay.date}T${this.tripCorrection.time[1]}`,
                trailer: this.tripCorrection.trailer?.id,
                truck: this.selectedTruck.toll_declaration.truck.id,
            })

            this.$notify(this.$t('correctionCreated'))
            this.toggleCorrectionForm()
        },
    },
}
</script>

<i18n>
{
    "en": {
        "addCorrection": "Add correction",
        "cancel": "Cancel",
        "correctionCreated": "Correction created",
        "create": "Save",
        "trailer": "Trailer",
        "trip": "Trip",
        "weight": "Weight"
    },
    "de": {
        "addCorrection": "Zwischenstrecke einfügen",
        "cancel": "Abbrechen",
        "correctionCreated": "Zwischenstrecke erstellt",
        "create": "Speichern",
        "trailer": "Anhänger",
        "trip": "Fahrt",
        "weight": "Gewicht"
    },
    "fr": {
        "addCorrection": "Ajouter une correction",
        "cancel": "Annuler",
        "correctionCreated": "Correction créée",
        "create": "Sauvegarder",
        "trailer": "Remorque",
        "trip": "Trajet",
        "weight": "Poids"
    },
    "it": {
        "addCorrection": "Aggiungi correzione",
        "cancel": "Annulla",
        "correctionCreated": "Correzione creata",
        "create": "Salva",
        "trailer": "Rimorchio",
        "trip": "Viaggio",
        "weight": "Peso"
    }
}
</i18n>

<style lang="scss" scoped>
.trip {
    border-bottom: $style-border;

    &__correction-form {
        padding: 24px;
        border-radius: 12px;
        background: $color-gray-light-2;

        &-header {
            font-size: 16px;
            font-weight: 700;
        }
    }
}
</style>
