<template>
    <button
        class="base-button"
        :disabled="disabled || isLoading"
        :class="{
            'base-button--with-icon':
                isDoneIconVisible || isFailIconVisible || isLoading,
            'base-button--small': size === 'small',
            'base-button--outline': variant === 'outline',
        }"
        v-on="$listeners"
    >
        <VSpinner
            v-if="isLoading"
            class="base-button__icon"
            size="18"
            :line-bg-color="colorIcon"
            :line-fg-color="colorSpinner"
            :line-size="2"
            :speed="1"
        />

        <CheckIcon
            v-else-if="isDoneIconVisible"
            class="base-button__icon"
            width="18"
            height="18"
            :color="colorIcon"
        />

        <RemoveIcon
            v-else-if="isFailIconVisible"
            class="base-button__icon"
            width="18"
            height="18"
            :color="colorIcon"
        />

        <slot />
    </button>
</template>

<script>
import VSpinner from 'vue-simple-spinner'

import CheckIcon from '../icons/CheckIcon'
import RemoveIcon from '../icons/RemoveIcon'

export default {
    name: 'BaseButton',
    components: {
        CheckIcon,
        RemoveIcon,
        VSpinner,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        variant: {
            type: String,
            default: 'solid',
            validator: value => ['solid', 'outline'].includes(value),
        },
        size: {
            type: String,
            default: 'default',
        },
    },
    data() {
        return {
            isDoneIconVisible: false,
            isFailIconVisible: false,
            colorSpinner: process.env.VUE_APP_COLOR_PRIMARY,
            colorIcon: 'rgba(255, 255, 255, 0.75)',
        }
    },
    methods: {
        showDoneIcon(duration = 1000) {
            this.isDoneIconVisible = true
            setTimeout(() => {
                this.isDoneIconVisible = false
            }, duration)
        },
        showFailIcon(duration = 1000) {
            this.isFailIconVisible = true
            setTimeout(() => {
                this.isFailIconVisible = false
            }, duration)
        },
    },
}
</script>

<style lang="scss" scoped>
.base-button {
    position: relative;
    padding: 20px;
    background-color: $color-primary;
    border: none;
    border-radius: 8px;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    font-family: 'IBM Plex Sans', sans-serif;
    color: $color-primary-contrast;
    transition: all 0.1s;
    outline: none;
    cursor: pointer;

    &:focus,
    &:hover {
        background-color: lighten($color-primary, 5%);
    }

    &:active {
        background-color: darken($color-primary, 1.25%);
    }

    &:disabled {
        background-color: $color-light;
        color: rgba(0, 0, 0, 0.2);
        cursor: default;
    }

    &--small {
        padding: 8px 16px;
        font-size: 14px;
        font-weight: 400;
        // TODO: need to investigate how crucial this is
        //border-radius: 0;
    }

    &--outline {
        background: $color-primary-contrast;
        border: 1px solid $color-primary;
        color: $color-primary;

        &:hover {
            background-color: $color-primary-light;
            color: $color-primary-contrast;
        }
    }

    &--with-icon {
        padding-left: 40px;
    }

    &__icon {
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);
    }
}
</style>
