<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :viewbox-width="24"
        :viewbox-height="24"
        :color="color"
        @click="$emit('click')"
    >
        <path
            class="a"
            d="M9,18V7.5H3.5A2.97,2.97,0,0,0,.75,10.65v6.3A.99.99,0,0,0,1.667,18H3.75"
        />

        <circle class="a" cx="5.25" cy="18" r="1.5" />

        <circle class="a" cx="17.25" cy="18" r="1.5" />

        <path class="a" d="M.75,12H5.125A.862.862,0,0,0,6,11.152V7.5" />

        <line class="a" x1="6.75" y1="18" x2="15.75" y2="18" />

        <line class="a" x1="0.75" y1="15" x2="2.707" y2="15" />

        <path
            class="a"
            d="M23.25,13.5H9V6a1.5,1.5,0,0,1,1.5-1.5H21.75A1.5,1.5,0,0,1,23.25,6Z"
        />

        <path class="a" d="M18.75,18h3a1.5,1.5,0,0,0,1.5-1.5v-3" />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'TrailerIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
