<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        fill-color="none"
        @click="$emit('click')"
    >
        <path class="a" d="M0.75 4.5h7.5" />
        <path class="a" d="M4.5 0.75v7.5" />
        <path
            class="a"
            d="m20.147 12.9 -4.7 -4.7 2.526 -2.526c0.1828 -0.18287 0.3998 -0.32794 0.6386 -0.42691 0.2388 -0.09898 0.4949 -0.14992 0.7534 -0.14992 0.2585 0 0.5145 0.05094 0.7534 0.14992 0.2388 0.09897 0.4558 0.24404 0.6386 0.42691l1.918 1.918c0.3688 0.36919 0.5759 0.86967 0.5759 1.3915 0 0.52182 -0.2071 1.0223 -0.5759 1.3915L20.147 12.9Z"
        />
        <path
            class="a"
            d="m20.147 12.9 -4.7 -4.70005L9.37499 10.748c-0.36212 0.1034 -0.6919 0.2974 -0.9582 0.5637 -0.2663 0.2664 -0.46034 0.5961 -0.5638 0.9583l-2.734 10.274c-0.02718 0.095 -0.02845 0.1955 -0.00367 0.2912 0.02477 0.0957 0.07469 0.183 0.14457 0.2529 0.06988 0.0698 0.15718 0.1198 0.25285 0.1445 0.09568 0.0248 0.19623 0.0235 0.29125 -0.0036L16.077 20.5c0.3627 -0.1041 0.693 -0.2989 0.9595 -0.5661 0.2665 -0.2672 0.4604 -0.5979 0.5635 -0.9609l2.547 -6.073Z"
        />
        <path class="a" d="m5.26001 23.088 7.46599 -7.467" />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'ActionIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
