import moment from 'moment'
import { httpHelper } from '@/utils'

const state = {
    calendarDays: [],
    isCalendarLoading: false,
    isCorrectionPending: false,
    isTrailersLoading: false,
    isTrucksLoading: false,
    isTripsLoading: false,
    searchQuery: '',
    searchStatus: '',
    selectedDay: {},
    selectedTruck: null,
    trailers: [],
    trips: [],
    trucks: [],
}

const getters = {
    preparedCalendarDays(state) {
        return state.calendarDays
            .map(item => ({
                date: item.date,
                label: moment(item.date).format('dd'),
                dayNumber: moment(item.date).date(),
                isSunday: moment(item.date).day() === 0, // Check if the day is Sunday
                hasMarker: item.needs_manual_correction,
                url: item.url,
            }))
            .reverse()
    },
    preparedTrips(state) {
        return state.trips.reduce((acc, item) => {
            return item.legs?.length ? [...acc, ...item.legs] : acc
        }, [])
    },
}

const actions = {
    async loadCalendarDays({ commit }) {
        commit('setCalendarLoading', true)
        commit('setCalendarDays', [])

        const { data } = await httpHelper.get(
            'lsva/regular-toll-declaration-calendar/'
        )

        commit('setCalendarDays', Object.freeze(data))
        commit('setCalendarLoading', false)
    },
    async loadTrucks({ commit, state }) {
        commit('setTrucksLoading', true)
        commit('setTrucks', [])
        commit('setSelectedTruck', null)

        let query = ''

        if (state.searchQuery && state.searchQuery.length > 0) {
            query += `&search=${state.searchQuery}`
        }

        if (state.searchStatus && state.searchStatus !== 'ALL') {
            query += `&declaration_type=${state.searchStatus}`
        }

        const url = `${state.selectedDay.url}${query}`

        const { data } = await httpHelper.get(url)

        commit('setTrucks', Object.freeze(data.results))
        commit('setTrucksLoading', false)
    },
    async loadTrips({ commit, state }) {
        commit('setTripsLoading', true)
        commit('setTrips', [])

        const url = `lsva/declarations/?truck=${state.selectedTruck.toll_declaration.truck.id}&declaration_period=${state.selectedDay.date}`

        const { data } = await httpHelper.get(url)

        commit('setTrips', Object.freeze(data?.results ?? []))
        commit('setTripsLoading', false)
    },
    async loadTrailers({ commit }) {
        commit('setTrailers', [])
        commit('setTrailersLoading', true)

        const { data } = await httpHelper.get('lsva/trailers/')

        commit('setTrailers', Object.freeze(data.results))
        commit('setTrailersLoading', false)
    },
    async createCorrection({ commit }, data) {
        commit('setCorrectionPending', true)

        await httpHelper.post('lsva/manual-toll-declarations/trailer/', data)

        commit('setCorrectionPending', false)
    },
    setSearchQuery({ commit, dispatch }, data) {
        commit('setSearchQuery', data)
        dispatch('loadTrucks')
    },
    setSearchStatus({ commit, dispatch }, data) {
        commit('setSearchStatus', data)
        dispatch('loadTrucks')
    },
    setSelectedDay({ commit, dispatch }, data) {
        commit('setSelectedDay', data)
        dispatch('loadTrucks')
    },
    setSelectedTruck({ commit, dispatch }, data) {
        commit('setSelectedTruck', data)
        dispatch('loadTrips')
    },
}
const mutations = {
    setCalendarLoading(state, data) {
        state.isCalendarLoading = data
    },
    setCalendarDays(state, data) {
        state.calendarDays = data
    },
    setTrucksLoading(state, data) {
        state.isTrucksLoading = data
    },
    setTrucks(state, data) {
        state.trucks = data
    },
    setSearchQuery(state, data) {
        state.searchQuery = data
    },
    setSearchStatus(state, data) {
        state.searchStatus = data
    },
    setSelectedDay(state, data) {
        state.selectedDay = data
    },
    setSelectedTruck(state, data) {
        state.selectedTruck = data
    },
    setTrips(state, data) {
        state.trips = data
    },
    setTripsLoading(state, data) {
        state.isTripsLoading = data
    },
    setTrailers(state, data) {
        state.trailers = data
    },
    setTrailersLoading(state, data) {
        state.isTrailersLoading = data
    },
    setCorrectionPending(state, data) {
        state.isCorrectionPending = data
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
